<template>
    <div class="col-lg col-xl-12 d-middle m-1 p-1">
        <div class="card-producto w-100 p-1 d-middle mr-2" :class="{'border-general':info.promocion && info.data_promocion.borde}" @click="$emit('accion',info)">
            <div class="position-relative">
                <img class="br-10 obj-cover" height="90" width="90" :src="info.producto.imagen" alt="" />
                <p v-show="pedido.impuestos === 2 && info.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center">IVA incluido</p>
                <p v-show="pedido.impuestos === 1 && info.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center"> + IVA</p>
            </div>
            <div class="col px-2 ">
                <div v-if="info.desconocido === 0" class="">
                    <p class="nombre lh-18 f-500 text-general">{{ info.producto.nombre }}</p>
                    <p class="text-general2 f-13">{{ info.producto.presentacion }}</p>
                    <div class="row mx-0 text-general align-items-center pb-1 justify-content-between">
                        <div v-if="info.sugerido">
                            <span v-if="info.sugerido.estado == 2 || info.sugerido.estado == 3" class="f-600">{{ convertMoney(info.sugerido.unidad_sugerida,pedido.idm_moneda) }}</span>
                            <span v-else class="f-600">{{ convertMoney(valor_producto,pedido.idm_moneda) }}</span>
                        </div>
                        <span v-else class="f-600">{{ convertMoney(valor_producto,pedido.idm_moneda) }}</span>
                        <p :class="{stock_bajo:info.stock_bajo}">
                            <b>{{ agregarSeparadoresNumero(info.cantidad,decimalFraccion) }}</b>
                            {{ info.producto.unidad }}
                        </p>
                    </div>
                    <span v-if="info.sugerido" class="bg-general3 br-10 text-white px-2 f-13">
                        {{ info.sugerido.porcentaje }}
                        ({{ info.sugerido.estado == 1 ? 'Pendiente' : info.sugerido.estado == 2 ? 'Aprobado' : info.sugerido.estado == 3 ? 'Aprobado' :'Rechazado' }})
                    </span>
                    <span v-if="info.sugerido == 0 && info.promocion" class="bg-general br-10 text-white px-2 f-12">{{ info.data_promocion.texto }}</span>
                </div>
                <div v-else class="">
                    <p class="nombre lh-18 f-600">Producto Desconocido</p>
                    <p>{{ info.producto.nombre }}</p>
                </div>
            </div>
        </div>
        <el-dropdown size="mini" placement="bottom" type="primary" @command="handleCommand">
            <span class="el-dropdown-link">
                <i :class="`${fun_icono(info.icono)} f-20`" />
            </span>
            <el-dropdown-menu v-if="$can('botones_abastecimiento_productos_icono')" slot="dropdown" class="br-10" style="width:45px;">
                <el-dropdown-item :command="null" :disabled="info.icono === null">
                    <i class="icon-sticker-emoji f-20" />
                </el-dropdown-item>
                <el-dropdown-item :command="1" :disabled="info.icono === 1">
                    <i class="icon-ok-circled-outline text-success f-20" />
                </el-dropdown-item>
                <el-dropdown-item :command="2" :disabled="info.icono === 2">
                    <i class="icon-cancel-circled-outline text-danger f-20" />
                </el-dropdown-item>
                <el-dropdown-item :command="3" :disabled="info.icono === 3">
                    <i class="icon-search text-primary f-20" />
                </el-dropdown-item>
                <el-dropdown-item :command="4" :disabled="info.icono === 4">
                    <i class="icon-currency-usd-circle text-aqua f-20" />
                </el-dropdown-item>
                <el-dropdown-item :command="5" :disabled="info.icono === 5">
                    <i class="icon-attention-outline text-gr-red f-20" />
                </el-dropdown-item>
                <el-dropdown-item :command="6" :disabled="info.icono === 6">
                    <i class="icon-descargar text-general f-20" />
                </el-dropdown-item>
                <el-dropdown-item :command="7" :disabled="info.icono === 7">
                    <i class="icon-shopping text-morado f-20" />
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    nombre: '',
                    foto_mini: '',
                    presentacion:  '600',
                    precio: 14000,
                    tipo: 1,
                    color: 0,
                    borde: true,
                    posicion: 0,
                    texto: 'MÁS BARATO',
                    descuento: 0,
                    estado: ''
                }
            }
        }
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            colores: [ '', 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: [ '', 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: [ '', 'border-blue', 'border-red', 'border-purple', 'border-pink' ],

        }
    },
    computed:{
        ...mapGetters({
            pedido: 'pedidos/pedidos_admin/pedido',
        }),
        decimalFraccion(){
            if(this.info.producto.cantidad_tipo === 1) return 0
            else return 2
        },
        valor_producto(){
            if(this.pedido.impuestos === 0){
                return this.info.unidad_final
            }
            if(this.pedido.impuestos === 1){
                return this.info.unidad_final
            }
            if(this.pedido.impuestos === 2 || this.pedido.impuestos === 3){
                return this.info.unidad_final * (1 + this.info.porcentaje_impuesto/100)
            }
            return 0
        }
    },
    methods: {
        handleCommand(e){
            this.info.icono = e
            this.$emit('icono',{id:this.info.id, icon:e})
        },
        fun_icono(icon){
            switch (icon){
            case null:
                return 'icon-sticker-emoji'
            case 1:
                return 'icon-ok-circled-outline text-success'
            case 2:
                return 'icon-cancel-circled-outline text-danger'
            case 3:
                return 'icon-search text-primary'
            case 4:
                return 'icon-currency-usd-circle text-aqua'
            case 5:
                return 'icon-attention-outline text-gr-red'
            case 6:
                return 'icon-descargar text-general'
            case 7:
                return 'icon-shopping text-morado'
            default:
                return 'icon-sticker-emoji'
            }
        }

    }
}
</script>

<style lang="css" scoped>
.card-producto{
    /* width: 400px; */
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
.stock_bajo{
  background: red;
  color: #fff !important;
  border-radius: 10px;
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.text-morado{
    color: #CC05A4
}
</style>
